import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../Styled/Container"
import { H1, H2, P } from "../Styled/Text"
import { TextButton } from "../Styled/Button"

export const StyledContainer = tw(Container)`max-w-lg mt-5 mb-5`
export const StyledH1 = tw(H1)`text-lg md:text-lg mt-5 mb-5 font-sans uppercase tracking-tense text-center`
export const StyledH2 = tw(H2)`text-center font-sans font-normal leading-relaxed uppercase text-lg  md:text-lg`
export const SeoDescription = tw(P)`text-center font-normal leading-relaxed p-1-6 max-w-md m-auto`

export const FiltersWrapper = styled(Container)`
  ${tw`sticky min-h-4-4 md:min-h-3-2 top-0 z-20 bg-light`}
`
export const Filters = styled.div`
  ${tw`bg-light text-dark w-full h-full flex items-center justify-between py-1 px-0 md:px-1-5 mx-auto max-w-lg relative`}
`
export const FiltersBorder = styled.div`
  ${tw`border-t absolute left-0 right-0 top-0 mx-0 md:mx-1-5`}
  border-color: #eeeeee
`
export const FilterColumn = styled.div`
  ${tw`w-1/2 items-center`}
  ${props => (props.end ? tw`justify-end` : props.center ? tw`justify-center -mx-30` : tw`justify-start`)}
  ${props => (props.desktop ? tw`hidden md:flex` : props.mobile ? tw`flex md:hidden` : tw`flex`)}
`
export const FilterText = styled(P)`
  ${tw`text-xs tracking-wider uppercase font-medium pr-0-8`}
  ${props => (props.grey ? tw`text-grey-darker` : tw``)}
`
export const Filter = styled(TextButton)`
  ${tw`text-xs tracking-wider uppercase px-0-8 hover:text-dark hover:no-underline`}
  ${tw`text-grey-darker font-medium`}
  ${props => (props.active ? tw`text-dark` : ``)}
`
export const StyledP = tw(P)`text-xs tracking-relaxed`

export const HeroWrapper = styled.div`
  ${tw`pb-5`} ${props => (props.condensed ? tw`md:pb-5` : tw`md:pb-16`)}
`
export const GridContainer = tw(Container)`pb-5 md:pb-5 max-w-lg`
export const Items = tw.div`flex flex-wrap items-start -m-0-6`
export const Brides = styled.div`
  ${tw`p-0-6 w-full`}
  ${props => (props.width === `1/2` ? tw`md:w-1/2` : props.width === `1/4` ? tw`md:w-1/4` : ``)}
  ${props => (props.sticky ? tw`md:sticky md:top-2` : ``)}
`

export const Card = styled.div`
  ${tw`w-full p-0-6`}
  ${props => (props.width === `1/2` ? tw`md:w-1/2` : props.width === `1/4` ? tw`md:w-1/4` : ``)}
`
export const Content = tw.div`relative`
export const Details = tw.div`w-full md:absolute md:left-0 md:bottom-0 md:px-1-6 md:py-1-2 py-0-8 text-left md:opacity-0 group-hover:opacity-100 transition-all duration-200 pointer-events-none`
export const Wishlist = tw.div`absolute top-0 right-0 pr-1-2 pt-1-2 md:opacity-0 group-hover:opacity-100 transition-all duration-200`
export const ImageWrapper = tw.div`relative`
export const ImageOverlay = tw.div`absolute inset-0 bg-light pointer-events-none transition-all duration-200 opacity-0 group-hover:opacity-85`
export const ImageRatio = styled.div`
  ${tw`block bg-grey-lightest`}
  ${props => (props.ratio === `0-6` ? `padding-bottom:calc(150% - 6px)` : tw`pt-150-p`)}
`
export const StyledImage = tw.div`relative`
export const StyledZoomImageWrapper = tw.div`relative`
export const StyledButton = tw.button`block w-full cursor-pointer focus:outline-none`
export const ZoomClose = styled.button`
  ${tw`absolute top-3-2 right-3-2 cursor-pointer hover:opacity-70 text-light focus:outline-none`}
`
