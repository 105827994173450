import React, { Fragment } from "react"

import { withBridesFilters } from "./withBridesFilters"
import { Select } from "../../Styled/Select/Select"
import { FiltersWrapper, Filters, FilterText, FilterColumn, Filter, FiltersBorder } from "../BridesStyles"

interface Props {
  availableVendors: Array<any>
  filters: string
  handleFilters: Function
  handleResetFilters: Function
  locales: any
}

export const BridesFilters = withBridesFilters(({ availableVendors, filters, handleFilters, handleResetFilters, locales }: Props) => (
  <FiltersWrapper>
    <Filters>
      <FiltersBorder />
      <FilterColumn>
        <FilterText>{`${locales?.filterBrides} ${filters ? filters : locales?.filterAll}`}</FilterText>
      </FilterColumn>

      <FilterColumn center={`true`} desktop>
        <FilterText grey={`true`}>{`${locales?.filterSortBy}`}</FilterText>
        <Filter active={!filters} onClick={() => handleResetFilters()}>
          {locales?.filterAll}
        </Filter>

        {availableVendors.map(vendor => (
          <Fragment key={vendor}>
            <Filter key={vendor} active={filters === vendor} onClick={() => handleFilters(vendor)}>
              {vendor}
            </Filter>
          </Fragment>
        ))}
      </FilterColumn>

      <FilterColumn end={`true`} mobile>
        <Select
          altIcon
          onChange={event => (event.target.value !== `` ? handleFilters(event.target.value) : handleResetFilters())}
          value={filters ? filters : ``}
          rightAligned
          uppercase
        >
          <option value={``}>{locales?.filterSortBy}</option>
          <option value={``}>{locales?.filterAll}</option>

          {availableVendors.map(vendor => (
            <option key={vendor} value={vendor}>
              {vendor}
            </option>
          ))}
        </Select>
      </FilterColumn>

      <FilterColumn end={`true`} desktop></FilterColumn>
    </Filters>
  </FiltersWrapper>
))
