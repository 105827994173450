import React from "react"
import { graphql } from "gatsby"

import { Brides as Page } from "../components/Brides/Brides"

export const query = graphql`
  query {
    page: sanityBridesPage {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
      heroBanner2: _rawHeroBanner2(resolveReferences: { maxDepth: 10 })
      heroBanner3: _rawHeroBanner3(resolveReferences: { maxDepth: 10 })
      description
      settings {
        dark
        transparent
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
