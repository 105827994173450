import React from "react"

import { Image } from "../../Image/Image"
import { Popup } from "../../Modals/Popup/Popup"
import { StyledIcon } from "../../Modals/Popup/PopupStyles"
import { StyledZoomImageWrapper, ZoomClose } from "../BridesStyles"

interface Props {
  setZoom: Function
  zoom: boolean
  zoomURL: string
}

export const BridesPopup = ({ zoom, setZoom, zoomURL }: Props) => (
  <Popup active={zoom ? true : false} noClose setActive={setZoom} transparent>
    <StyledZoomImageWrapper>
      <Image maxV src={zoomURL} />

      <ZoomClose onClick={() => setZoom(false)}>
        <StyledIcon />
      </ZoomClose>
    </StyledZoomImageWrapper>
  </Popup>
)
