import { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useSanity } from "./useSanity"

const query = graphql`
  query BRIDES {
    sanityBridesPage {
      brides: _rawBrides(resolveReferences: { maxDepth: 10 })
    }
  }
`

export const useBrides = () => {
  const { textNormaliser } = useSanity()
  const data = useStaticQuery(query)

  const brides =
    data?.sanityBridesPage?.brides.map(bride => ({
      ...bride,
      info: textNormaliser(bride.info),
    })) || []

  const [filteredBrides, setFilteredBrides] = useState(brides)
  const [filters, setFilters] = useState(null)

  const handleFilters = value => {
    setFilters(value)
  }

  const handleResetFilters = () => {
    setFilters(null)
  }

  const availableVendors = brides
    .filter(bride => bride?.vendor?.title)
    .map(bride => (bride.vendor ? bride.vendor.title : ""))
    .filter((item, index, array) => array.indexOf(item) === index)
    .sort()

  useEffect(() => {
    const filteredBrides = brides.filter(bride => bride?.vendor?.title === filters || !filters)
    setFilteredBrides(filteredBrides)
  }, [filters])

  return {
    filteredBrides,
    handleResetFilters,
    availableVendors,
    filters,
    handleFilters,
  }
}
